/* Batasi styling hanya dalam .article-container */
.article-container {
    @apply mx-auto py-6 px-4 sm:py-10 sm:px-5;
}

/* Heading Styling dalam Artikel */
.article-container h1 {
    @apply text-2xl sm:text-3xl font-bold text-gray-900 mb-4;
}

.article-container h2 {
    @apply text-xl sm:text-2xl font-bold text-gray-800 mt-6 mb-3;
}

.article-container h3 {
    @apply text-lg sm:text-xl font-semibold text-gray-700 mt-4 mb-2;
}

.article-container strong {
    @apply font-semibold text-gray-900;
}

/* Paragraph Styling */
.article-container p {
    @apply leading-relaxed text-gray-700 mb-4 text-base sm:text-lg;
}

/* Pastikan bullet list muncul */
.article-container ul {
    @apply list-disc list-inside pl-5 mb-4;
}

.article-container ol {
    @apply list-decimal list-inside pl-5 mb-4;
}

/* Pastikan nested list tetap terlihat */
.article-container ul ul,
.article-container ol ul {
    @apply list-disc pl-6;
}

.article-container ul ol,
.article-container ol ol {
    @apply list-decimal pl-6;
}

/* Styling untuk setiap item di dalam list */
.article-container li {
    @apply mb-2 text-gray-700 text-base sm:text-lg;
}

/* Blockquote Styling */
.article-container blockquote {
    @apply italic border-l-4 border-gray-500 pl-4 mt-4 mb-6 text-gray-700;
}

/* Custom Styling untuk List dalam Div */
.article-container div>div>ul {
    @apply list-disc pl-6 mb-4;
}

.article-container div>div>ul li {
    @apply text-gray-600;
}